<template>
    <el-card>
      <select-image multiple></select-image>
    </el-card>
</template>

<script>
import selectImage from '@/components/Images'

export default {
    name: 'console-images',
    data() {
      return {}
    },
    components: {
      selectImage
    }
}
</script>